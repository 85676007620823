import React from 'react';

export enum ResolutionValues {
    VerySmall,
    Small,
    Middle,
    Large,
    VeryLarge,
    HugeLarge,
    SignificantLarge,
}

export interface IViewportContext {
    width: number;
    height: number;
    resolution: ResolutionValues;
}

const viewportContext = React.createContext<IViewportContext>({ width: 0, height: 0, resolution: ResolutionValues.VerySmall });

export const ViewportProvider = ({ children }: React.PropsWithChildren) => {
    const [width, setWidth] = React.useState(window.innerWidth);
    const [height, setHeight] = React.useState(window.innerHeight);

    const handleWindowResize = () => {
        setWidth(window.innerWidth);
        setHeight(window.innerHeight);
    };

    React.useEffect(() => {
        window.addEventListener('resize', handleWindowResize);
        return () => window.removeEventListener('resize', handleWindowResize);
    }, []);
    const resolution =
        width >= 2000
            ? ResolutionValues.SignificantLarge
            : width >= 1600
              ? ResolutionValues.HugeLarge
              : width >= 1200
                ? ResolutionValues.VeryLarge
                : width >= 992
                  ? ResolutionValues.Large
                  : width >= 768
                    ? ResolutionValues.Middle
                    : width >= 576
                      ? ResolutionValues.Small
                      : ResolutionValues.VerySmall;
    return <viewportContext.Provider value={{ width, height, resolution }}>{children}</viewportContext.Provider>;
};

export const useViewport = (): IViewportContext => {
    return React.useContext(viewportContext);
};
