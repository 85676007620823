import React, { useEffect, useState } from 'react';
import '@arco-design/web-react/dist/css/arco.css';

import './App.css';
import { ResolutionValues, useViewport } from './viewport';



function App() {
  const { resolution } = useViewport();
  const large = resolution >= ResolutionValues.Large;
  return (
    <div className={large?"large both":"small both"}>
      <div className="header">
          {large?<div style={{width:"148px"}}></div>:""}
          <img style={{marginLeft:large?undefined:"16px"}} className="brand" src="/brand.jpg" alt=""></img>
          <a href="mailto:hi@redshift42.com" style={{marginRight:large?"48px":"16px", textAlign:"right", width:"100px"}}>CONTACT</a>
      </div>
      <div className="content">
        {large?<div className="tips" style={{marginBottom:"71px"}}>
          <span>
            Focusing on enabling human imagination to run free
          </span>
          <span>
            through innovative technologies
          </span>
        </div>:<div className="tips" style={{marginBottom:"165px"}}>
          <span>
            Focusing on enabling human
          </span>
          <span>
            imagination to run free through
          </span>
          <span>
            innovative technologies
          </span>
        </div>}
        <img className="brand" style={{marginBottom:large?"45px":"54px"}} src="/brand.jpg" alt=""></img>
      </div>
      <img className="background" src={large?"bg_large.jpg":"bg_small.jpg"} alt="">
      </img>
    </div>
  );
}

export default App;
